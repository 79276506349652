<template>
  <div class="shortlists-selection-inner">
    <h4>{{ type }} upsell lists</h4>

    <simplebar
      v-if="type == 'cart'"
      class="shortlists-list"
      data-simplebar-auto-hide="false"
    >
      <div
        v-for="list in currentUpsellLists(search, type)"
        :key="list.id"
        class="shortlist-item"
        :class="{ active: selectedUpsellList === list.id }"
        @click="selectUpsellList(list.id)"
      >
        <span class="name">{{ list.name }}</span>
      </div>
    </simplebar>

    <draggable
      v-else
      class="shortlists-products-inner"
      v-model="homeUpsellLists"
      @start="onDragStart"
      @end="onDragEnd"
    >
      <div
        v-for="list in homeUpsellListsWithData"
        :key="list.id"
        class="shortlist-item"
        :class="{ active: selectedUpsellList === list.id }"
        @click="selectUpsellList(list.id)"
      >
        <span class="name">{{ list.name }}</span>
      </div>
    </draggable>

    <div v-if="type == 'cart'" class="shortlist-search">
      <span class="material-icons">search</span>
      <input
        @input="debounceSearch"
        type="search"
        ref="upsellListSearch"
        placeholder="Search list..."
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import draggable from "vuedraggable";
import { db } from "../../main";

export default {
  name: "UpsellListSelector",
  data() {
    return {
      search: "",
      timeout: null,
      test: [],
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      selectedUpsellList: (state) => state.cartUpsell.selectedUpsellList,
      selectedCompanies: (state) => state.cartUpsell.selectedCompanies,
      homeUpsellLists: (state) => state.cartUpsell.homeUpsellLists,
    }),
    ...mapGetters("cartUpsell", [
      "currentUpsellLists",
      "currentlySelectedCompanies",
      "homeUpsellListsWithData",
    ]),
    homeUpsellLists: {
      get() {
        return this.$store.getters["cartUpsell/homeUpsellLists"];
      },
      set(value) {
        let i = 0;
        let data = {};

        value.forEach((id) => {
          data[id] = i;
          i++;
        });

        db.collection("app").doc("appHelpers").update({
          homeUpsellOrder: data,
        });
      },
    },
  },
  components: {
    simplebar,
    draggable,
  },
  methods: {
    onDragStart: function (evt) {
      console.log(evt);
      // this.$store.commit("notifications/setSelectedBlockIndex", evt.oldIndex);
    },
    onDragEnd: function (evt) {
      console.log({
        item: evt.item,
        from: evt.from,
        to: evt.to,
        oldIndex: evt.oldIndex,
        newIndex: evt.newIndex,
      });

      // this.$store.commit("notifications/setSelectedBlockIndex", evt.newIndex);
    },
    debounceSearch: function () {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.search = this.$refs.upsellListSearch.value;
      }, 500);
    },
    selectUpsellList: function (listId) {
      if (this.currentlySelectedCompanies.length > 0) {
        this.$store.commit("cartUpsell/setSetListsConfirm", true);
        this.$store.commit("cartUpsell/setSelectedUpsellList", listId);
      } else {
        if (listId == this.selectedUpsellList) {
          this.$store.commit("cartUpsell/setSelectedUpsellList", null);
        } else {
          this.$store.commit("cartUpsell/setSelectedUpsellList", listId);
          this.$store.dispatch("cartUpsell/setupEditing");
        }
      }
    },
  },
  created() {
    console.log(this.currentUpsellLists(""));
  },
};
</script>
