import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";

Vue.use(Vuex);

import users from "./users";
import products from "./products";
import settings from "./settings";
import companies from "./companies";
import logs from "./logs";
import orders from "./orders";
import notifications from "./notifications";
import sharedShortlists from "./sharedShortlists";
import companyShortlists from "./companyShortlists";
import massEditShortlists from "./massEditShortlists";
import priceLists from "./priceLists";
import languages from "./languages";
import analytics from "./analytics";
import registerAnalytics from "./registerAnalytics";
import cartUpsell from "./cartUpsell";
import chat from "./chat";
import productsGS1 from "./productsGS1";
import popups from "./popups";
import categories from "./categories";
import surveys from "./surveys";
import massAddShortlists from "./massAddShortlists";

export default new Vuex.Store({
  state: {
    loading: false,
  },
  mutations: {
    // other mutations
    ...vuexfireMutations,
    setLoading(state, data) {
      state.loading = data;
    },
  },
  actions: {},
  modules: {
    users,
    products,
    settings,
    logs,
    companies,
    orders,
    notifications,
    sharedShortlists,
    companyShortlists,
    massEditShortlists,
    priceLists,
    languages,
    analytics,
    registerAnalytics,
    cartUpsell,
    chat,
    productsGS1,
    popups,
    categories,
    surveys,
    massAddShortlists,
  },
});
