<template>
  <div class="shortlist-edit-footer">
    <button @click="exitList" class="leave-button">Exit without saving</button>
    <button @click="saveList">Save</button>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "UpsellEditFooter",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading: (state) => state.cartUpsell.loading,
    }),
  },
  components: {},
  methods: {
    exitList: function () {
      if (this.loading === true) {
        return;
      }
      this.$store.dispatch("cartUpsell/exitEditing");
    },
    saveList: function () {
      if (this.loading === true) {
        return;
      }
      this.$store.commit("cartUpsell/setLoading", true);

      this.$store.dispatch("cartUpsell/saveList").then(() => {
        this.$store.commit("cartUpsell/setLoading", false);
      });
    },
  },
};
</script>
