import { db } from "../main.js";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";
import Vue from "vue";
import crypto from "crypto";

const notifications = {
  namespaced: true,
  state: {
    currentNav: "list",
    selectedCompanyCategories: [],
    selectedPriceGroups1: [],
    selectedPriceGroups2: [],
    selectedPriceGroups3: [],
    selectedRoutes: [],
    excludedPriceGroups1: [],
    excludedPriceGroups2: [],
    excludedPriceGroups3: [],
    excludedRoutes: [],
    selectedCompanies: [],
    selectedSalesAdmins: [],
    excludedCompanies: [],
    builder: false,
    mainTitle: "",
    mainDescription: "",
    startTime: null,
    endTime: null,
    postType: null,
    postStatus: "draft",
    blockTypes: ["title", "content", "image", "product", "button"],
    blocks: [],
    selectedBlockIndex: null,
    posts: [],
    editing: null,
    sendNotification: false,
    sendChat: false,
    notificationText: "",
    released: false,
    imageUrls: {},
    showOnlyOwnPosts: false,
    templates: [],
    init: false,
    featuredImage: null,
    doFeaturedUpload: false,
  },
  mutations: {
    setNavigation: (state, payload) => {
      state.currentNav = payload;
    },
    setSelectedCompanyCategories: (state, payload) => {
      state.selectedCompanyCategories = payload;
    },
    setSelectedPriceGroups1: (state, payload) => {
      state.selectedPriceGroups1 = payload;
    },
    setSelectedPriceGroups2: (state, payload) => {
      state.selectedPriceGroups2 = payload;
    },
    setSelectedPriceGroups3: (state, payload) => {
      state.selectedPriceGroups3 = payload;
    },
    setExcludedPriceGroups1: (state, payload) => {
      state.excludedPriceGroups1 = payload;
    },
    setExcludedPriceGroups2: (state, payload) => {
      state.excludedPriceGroups2 = payload;
    },
    setExcludedPriceGroups3: (state, payload) => {
      state.excludedPriceGroups3 = payload;
    },
    setSelectedRoutes: (state, payload) => {
      state.selectedRoutes = payload;
    },
    setSelectedCompanies: (state, payload) => {
      state.selectedCompanies = payload;
    },
    setSelectedSalesAdmins: (state, payload) => {
      state.selectedSalesAdmins = payload;
    },
    setExcludedCompanies: (state, payload) => {
      state.excludedCompanies = payload;
    },
    setExcludedRoutes: (state, payload) => {
      state.excludedRoutes = payload;
    },
    setBuilder: (state, payload) => {
      state.builder = payload;
    },
    setMainTitle: (state, payload) => {
      state.mainTitle = payload;
    },
    setMainDescription: (state, payload) => {
      state.mainDescription = payload;
    },
    setStartTime: (state, payload) => {
      state.startTime = payload;
    },
    setEndTime: (state, payload) => {
      state.endTime = payload;
    },
    setPostType: (state, payload) => {
      state.postType = payload;
    },
    setPostStatus: (state, payload) => {
      state.postStatus = payload;
    },
    updateBlocks: (state, payload) => {
      state.blocks = payload;
    },
    addBlock: (state, payload) => {
      state.blocks.push(payload);
    },
    setSelectedBlockIndex: (state, payload) => {
      state.selectedBlockIndex = payload;
    },
    setEditing: (state, payload) => {
      state.editing = payload;
    },
    setSendNotification: (state, payload) => {
      state.sendNotification = payload;
    },
    setSendChat: (state, payload) => {
      state.sendChat = payload;
    },
    setNotificationText: (state, payload) => {
      state.notificationText = payload;
    },
    setReleased: (state, payload) => {
      state.released = payload;
    },
    setImageUrls: (state, payload) => {
      state.imageUrls = payload;
    },
    setShowOnlyOwnPosts: (state, payload) => {
      state.showOnlyOwnPosts = payload;
    },
    setInit: (state, payload) => {
      state.init = payload;
    },
    setFeaturedImage: (state, payload) => {
      state.featuredImage = payload;
    },
    setDoFeaturedUpload: (state, payload) => {
      state.doFeaturedUpload = payload;
    },
  },
  getters: {
    searchTemplates: (state) => (search) => {
      if (search === "") {
        return state.templates;
      } else {
        return state.templates.filter((template) =>
          template.name.toLowerCase().includes(search.toLowerCase())
        );
      }
    },
    publishedPosts: (state, getters, rootState) => {
      if (state.showOnlyOwnPosts) {
        return state.posts
          .filter((post) => post.status === "publish")
          .filter((post) => post.authorId === rootState.settings.user.id)
          .sort((a, b) => b.createdAt - a.createdAt);
      } else {
        return state.posts
          .filter((post) => post.status === "publish")
          .sort((a, b) => b.createdAt - a.createdAt);
      }
    },
    filteredTemplates: (state, getters, rootState) => {
      if (state.showOnlyOwnPosts) {
        return state.templates.filter(
          (template) => template.authorId === rootState.settings.user.id
        );
      } else {
        return state.templates;
      }
    },
    draftPosts: (state, getters, rootState) => {
      if (state.showOnlyOwnPosts) {
        return state.posts
          .filter((post) => post.status === "draft")
          .filter((post) => post.authorId === rootState.settings.user.id)
          .sort((a, b) => b.createdAt - a.createdAt);
      } else {
        return state.posts
          .filter((post) => post.status === "draft")
          .sort((a, b) => b.createdAt - a.createdAt);
      }
    },
    activePosts: (state, getters) => {
      return getters.publishedPosts
        .filter((post) => {
          const now = new Date();
          const start = post.startTime.toDate();

          if (post.endTime !== null) {
            const end = post.endTime.toDate();
            if (now > end) {
              return false;
            }
          }
          if (now > start) {
            return true;
          }

          return false;
        })
        .sort((a, b) => b.startTime - a.startTime);
    },
    upcomingPosts: (state, getters) => {
      return getters.publishedPosts.filter((post) => {
        const now = new Date();
        const start = post.startTime.toDate();

        if (start > now) {
          return true;
        }

        return false;
      });
    },
    expiredPosts: (state, getters) => {
      return getters.publishedPosts.filter((post) => {
        const now = new Date();

        if (post.endTime !== null) {
          const end = post.endTime.toDate();
          if (now > end) {
            return true;
          }
        }

        return false;
      });
    },
    selectedBlock: (state) => {
      if (state.selectedBlockIndex === null) {
        return false;
      }

      return state.blocks[state.selectedBlockIndex];
    },
    finalCompanies: (state, getters, rootState) => {
      let companies = rootState.companies.companies;
      return companies
        .filter((company) => {
          if (
            state.selectedCompanyCategories.length > 0 &&
            state.selectedCompanyCategories.indexOf(
              company.companyCategory.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedPriceGroups1.length > 0 &&
            state.selectedPriceGroups1.indexOf(
              company.companyPriceGroup.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedPriceGroups2.length > 0 &&
            state.selectedPriceGroups2.indexOf(
              company.companyPriceGroup2.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedPriceGroups3.length > 0 &&
            state.selectedPriceGroups3.indexOf(
              company.companyPriceGroup3.toString()
            ) !== -1
          ) {
            return true;
          }

          if (company.route === undefined) {
            console.log(company);
          }

          if (
            state.selectedRoutes.length > 0 &&
            state.selectedRoutes.indexOf(company.route.toString()) !== -1
          ) {
            return true;
          }

          if (
            state.selectedCompanies.length > 0 &&
            state.selectedCompanies.indexOf(company.vismaId) !== -1
          ) {
            return true;
          }

          return false;
        })
        .filter((company) => {
          if (
            state.excludedCompanies.length > 0 &&
            state.excludedCompanies.indexOf(company.vismaId) !== -1
          ) {
            return false;
          }

          if (
            state.excludedPriceGroups1.length > 0 &&
            state.excludedPriceGroups1.indexOf(
              company.companyPriceGroup.toString()
            ) !== -1
          ) {
            return false;
          }

          if (
            state.excludedPriceGroups2.length > 0 &&
            state.excludedPriceGroups2.indexOf(
              company.companyPriceGroup2.toString()
            ) !== -1
          ) {
            return false;
          }

          if (
            state.excludedPriceGroups3.length > 0 &&
            state.excludedPriceGroups3.indexOf(
              company.companyPriceGroup3.toString()
            ) !== -1
          ) {
            return false;
          }

          if (
            state.excludedRoutes.length > 0 &&
            state.excludedRoutes.indexOf(company.route.toString()) !== -1
          ) {
            return false;
          }

          return true;
        })
        .filter((company) => {
          if (state.selectedSalesAdmins.length > 0) {
            if (
              state.selectedSalesAdmins.find(
                (salesAdmin) => salesAdmin === company.accountOwnerVismaId
              ) !== undefined
            ) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        })
        .map((company) => {
          return {
            vismaId: company.vismaId,
            firebaseId: company.id,
          };
        });
    },
    finalUsers: (state, getters, rootState) => {
      let users = rootState.users.userList;

      return users
        .filter((user) => {
          if (getters.finalCompanies.indexOf(user.companyId) !== -1) {
            return true;
          }

          return false;
        })
        .map((user) => {
          return user.uid;
        });
    },
    templateById: (state) => (id) => {
      return state.templates.find((template) => template.id === id);
    },
    templateNameById: (state) => (id) => {
      const template = state.templates.find((template) => template.id === id);

      if (template !== undefined) {
        return template.name;
      } else {
        return "Template deleted.";
      }
    },
  },
  actions: {
    bindPosts: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "posts",
        db.collection("posts").orderBy("createdAt", "desc").limit(300)
      );
    }),
    bindTemplates: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "templates",
        db
          .collection("posts")
          .doc("settings")
          .collection("templates")
          .orderBy("name")
      );
    }),
    unbindPosts: firestoreAction((context) => {
      context.unbindFirestoreRef("posts");
    }),
    unbindTemplates: firestoreAction((context) => {
      context.unbindFirestoreRef("templates");
    }),
    updateSelection: function (context, payload) {
      const type = payload.type;
      const field = payload.field;
      const value = payload.value;

      let newSelection = context.state["selected" + field];

      // Do array action.
      if (type === "add") {
        newSelection = newSelection
          .filter((val) => val !== value)
          .concat(value);
      } else if (type === "remove") {
        newSelection = newSelection.filter((val) => val !== value);
      }

      context.commit("setSelected" + field, newSelection);

      console.log(context.state["selected" + field]);
    },
    selectAll: function (context, payload) {
      const selection = Object.keys(payload).map((key) => {
        return key;
      });
      context.commit("setSelectedCompanyCategories", selection);
    },
    updateExclusion: function (context, payload) {
      const type = payload.type;
      const field = payload.field;
      const value = payload.value;

      let newSelection = context.state["excluded" + field];

      // Do array action.
      if (type === "add") {
        newSelection = newSelection
          .filter((val) => val !== value)
          .concat(value);
      } else if (type === "remove") {
        newSelection = newSelection.filter((val) => val !== value);
      }

      context.commit("setExcluded" + field, newSelection);
    },
    savePost: firestoreAction((context, payload) => {
      console.log(payload);

      let startTime = null;
      let startTimeChatFormat = null;
      let endTime = null;

      if (context.state.startTime !== null) {
        startTime = firebase.firestore.Timestamp.fromDate(
          Vue.moment(context.state.startTime, "YYYY-MM-DD — HH:mm").toDate()
        );
        startTimeChatFormat = Vue.moment(
          context.state.startTime,
          "YYYY-MM-DD — HH:mm"
        )
          .format("D MMM, hh:mm")
          .toLowerCase();
      }

      if (context.state.endTime !== null) {
        endTime = firebase.firestore.Timestamp.fromDate(
          Vue.moment(context.state.endTime, "YYYY-MM-DD — HH:mm").toDate()
        );
      }

      let selections = {
        selectedCompanyCategories: context.state.selectedCompanyCategories,
        selectedPriceGroups1: context.state.selectedPriceGroups1,
        selectedPriceGroups2: context.state.selectedPriceGroups2,
        selectedPriceGroups3: context.state.selectedPriceGroups3,
        excludedPriceGroups1: context.state.excludedPriceGroups1,
        excludedPriceGroups2: context.state.excludedPriceGroups2,
        excludedPriceGroups3: context.state.excludedPriceGroups3,
        selectedCompanies: context.state.selectedCompanies,
        selectedSalesAdmins: context.state.selectedSalesAdmins,
        excludedCompanies: context.state.excludedCompanies,
        finalCompanies: context.getters.finalCompanies,
      };

      let validatedBlocks = []; // used to save on post.

      let filesToUpload = [];
      let blocks = context.state.blocks;

      // Get all files to upload, and prepare blocks.
      blocks.forEach((block) => {
        let returnBlock;
        if (block.type === "image" && block.doUpload === true) {
          const uniqueFileName =
            payload.authorId + "_" + crypto.randomBytes(20).toString("hex");

          if (block.imageFile.name === undefined) {
            console.log("!!!!!!!! File upload will fail");
          }

          filesToUpload.push({
            file: block.imageFile,
            metadata: {
              contentType: block.imageFileType,
              customMetadata: {
                postId: null,
                type: "post",
                fileName: uniqueFileName,
              },
            },
          });

          returnBlock = {
            doUpload: false,
            imageBlob: block.imageBlob,
            imageFile: null,
            imageFileType: block.imageFileType,
            imageUniqueName: uniqueFileName,
            imageUrl: null,
            type: "image",
          };
        } else {
          returnBlock = block;
        }

        validatedBlocks.push(returnBlock);
      });

      // Handle featured image upload.
      if (context.state.doFeaturedUpload) {
        const uniqueFileName =
          payload.authorId + "_" + crypto.randomBytes(20).toString("hex");

        filesToUpload.push({
          file: context.state.featuredImage.file,
          metadata: {
            contentType: context.state.featuredImage.type,
            customMetadata: {
              postId: null,
              type: "post",
              fileName: uniqueFileName,
            },
          },
        });

        context.commit("setFeaturedImage", uniqueFileName);
      }

      let description = context.state.mainDescription;
      if (description === undefined || description === null) {
        description = "";
      }

      let featuredImage = context.state.featuredImage;
      if (
        context.state.featuredImage === undefined ||
        context.state.featuredImage === null
      ) {
        featuredImage = null;
      }

      let postData = {
        blocks: validatedBlocks,
        mainTitle: context.state.mainTitle,
        mainDescription: description,
        startTime: startTime,
        startTimeReadable: context.state.startTime,
        startTimeChatFormat: startTimeChatFormat,
        endTimeReadable: context.state.endTime,
        endTime: endTime,
        selections: selections,
        type: context.state.postType,
        status: context.state.postStatus,
        sendNotification: context.state.sendNotification,
        sendChat: context.state.sendChat,
        notificationText: context.state.notificationText,
        released: context.state.released,
        imageUrls: context.state.imageUrls,
        featuredImage: featuredImage,
      };

      console.log(postData);

      let postId;

      // Save post to database
      const p1 = new Promise((resolve) => {
        if (context.state.editing === null) {
          postData.createdAt = firebase.firestore.Timestamp.now();
          postData.authorId = payload.authorId;
          postData.authorName = payload.authorName;

          return db
            .collection("posts")
            .add(postData)
            .then((doc) => {
              postId = doc.id;
              console.log("POST ID: " + doc.id);
              context.commit("setEditing", doc.id);
              resolve();
            });
        } else {
          // update news.

          // Prevent notification from sending twice.
          delete postData.released;

          return db
            .collection("posts")
            .doc(context.state.editing)
            .update(postData)
            .then(() => {
              postId = context.state.editing;
              resolve();
            });
        }
      });

      return Promise.all([p1]).then(() => {
        if (filesToUpload.length > 0) {
          console.log(filesToUpload);
          return context
            .dispatch("uploadImages", {
              filesToUpload: filesToUpload,
              postId: postId,
              type: "post",
            })
            .then((res) => {
              if (res.length > 0) {
                console.log("Failed " + res.length + " uploads, retrying");
              } else {
                console.log("All uploads completed successfully");
              }
              context.dispatch("clearBuilder");

              return "success";
            });
        } else {
          return "success";
        }
      });
    }),
    uploadImages: (context, payload) => {
      const postId = payload.postId;
      const filesToUpload = payload.filesToUpload;
      const type = payload.type;

      let failedFiles = [];

      let promises = filesToUpload.map((file) => {
        return new Promise((resolve) => {
          let remoteFullPath;

          if (type === "post") {
            remoteFullPath =
              "posts/" + postId + "/" + file.metadata.customMetadata.fileName;
          } else if (type === "template") {
            remoteFullPath =
              "posts/settings/templates/" +
              postId +
              "/" +
              file.metadata.customMetadata.fileName;
          }

          const storageRef = firebase.storage().ref().child(remoteFullPath);
          file.metadata.customMetadata.postId = postId;

          console.log("ABOUT TO UPLOAD: ");
          console.log(file);
          console.log(remoteFullPath);

          return storageRef.put(file.file, file.metadata).then((snapshot) => {
            if (snapshot.totalBytes === 0) {
              failedFiles.push(file);
            }
            console.log(snapshot);
            resolve();
          });
        });
      });

      return Promise.all(promises).then(() => {
        return failedFiles;
      });
    },
    clearBuilder: (context) => {
      context.commit("setSelectedBlockIndex", null);
      context.commit("setSelectedCompanyCategories", []);
      context.commit("setSelectedPriceGroups1", []);
      context.commit("setSelectedPriceGroups2", []);
      context.commit("setSelectedPriceGroups3", []);
      context.commit("setExcludedPriceGroups1", []);
      context.commit("setExcludedPriceGroups2", []);
      context.commit("setExcludedPriceGroups3", []);
      context.commit("setSelectedCompanies", []);
      context.commit("setSelectedSalesAdmins", []);
      context.commit("setExcludedCompanies", []);
      context.commit("setMainTitle", "");
      context.commit("setMainDescription", "");
      context.commit("setStartTime", null);
      context.commit("setEndTime", null);
      context.commit("setPostType", null);
      context.commit("setPostStatus", "draft");
      context.commit("updateBlocks", []);
      context.commit("setEditing", null);
      context.commit("setBuilder", false);
      context.commit("setSendNotification", false);
      context.commit("setSendChat", false);
      context.commit("setNotificationText", "");
      context.commit("setReleased", false);
      context.commit("setImageUrls", {});
      context.commit("setDoFeaturedUpload", false);
      context.commit("setFeaturedImage", null);
      context.commit("setSelectedRoutes", []);
      context.commit("setExcludedRoutes", []);
    },
    editPost: firestoreAction((context, payload) => {
      return db
        .collection("posts")
        .doc(payload)
        .get()
        .then((doc) => {
          let docData = doc.data();

          context.commit("setEditing", payload);

          const blocks = docData.blocks.map((block) => {
            if (block.type === "title" && block.titleSize === undefined) {
              block.titleSize = "h1";
            }
            return block;
          });

          context.commit("setMainTitle", docData.mainTitle);
          context.commit("setMainDescription", docData.mainDescription);
          context.commit("setStartTime", docData.startTimeReadable);
          context.commit("setEndTime", docData.endTimeReadable);
          context.commit("updateBlocks", blocks);
          context.commit("setPostType", docData.type);
          context.commit("setPostStatus", docData.status);
          context.commit("setFeaturedImage", docData.featuredImage);

          if (docData.imageUrls === undefined) {
            context.commit("setImageUrls", {});
          } else {
            context.commit("setImageUrls", docData.imageUrls);
          }

          if (docData.sendNotification === undefined) {
            context.commit("setSendNotification", false);
          } else {
            context.commit("setSendNotification", docData.sendNotification);
          }

          if (docData.sendChat === undefined) {
            context.commit("setSendChat", false);
          } else {
            context.commit("setSendChat", docData.sendChat);
          }

          if (docData.notificationText === undefined) {
            context.commit("setNotificationText", "");
          } else {
            context.commit("setNotificationText", docData.notificationText);
          }

          if (docData.released === undefined) {
            context.commit("setReleased", false);
          } else {
            context.commit("setReleased", docData.released);
          }

          context.commit(
            "setSelectedCompanyCategories",
            docData.selections.selectedCompanyCategories
          );
          context.commit(
            "setSelectedPriceGroups1",
            docData.selections.selectedPriceGroups1
          );
          context.commit(
            "setSelectedPriceGroups2",
            docData.selections.selectedPriceGroups2
          );
          context.commit(
            "setSelectedPriceGroups3",
            docData.selections.selectedPriceGroups3
          );

          if (docData.selections.excludedPriceGroups1 !== undefined) {
            context.commit(
              "setExcludedPriceGroups1",
              docData.selections.excludedPriceGroups1
            );
          } else {
            context.commit("setExcludedPriceGroups1", []);
          }

          if (docData.selections.excludedPriceGroups2 !== undefined) {
            context.commit(
              "setExcludedPriceGroups2",
              docData.selections.excludedPriceGroups2
            );
          } else {
            context.commit("setExcludedPriceGroups2", []);
          }

          if (docData.selections.excludedPriceGroups3 !== undefined) {
            context.commit(
              "setExcludedPriceGroups3",
              docData.selections.excludedPriceGroups3
            );
          } else {
            context.commit("setExcludedPriceGroups3", []);
          }

          context.commit(
            "setSelectedCompanies",
            docData.selections.selectedCompanies
          );
          context.commit(
            "setSelectedSalesAdmins",
            docData.selections.selectedSalesAdmins
          );
          context.commit(
            "setExcludedCompanies",
            docData.selections.excludedCompanies
          );

          context.commit("setBuilder", true);
          context.commit("setNavigation", "create-edit");
        });
    }),
    removePost: firestoreAction((context) => {
      if (context.state.editing !== null) {
        return db
          .collection("posts")
          .doc(context.state.editing)
          .delete()
          .then(() => {
            console.log("delete complete");
            context.dispatch("clearBuilder");
          });
      }
      return false;
    }),
    /* eslint-disable */

    saveTemplate: firestoreAction((context, payload) => {
      let validatedBlocks = []; // used to save on post.

      const authorId = context.rootState.settings.user.id;
      const authorName = context.rootState.settings.user.name;

      // Get all files to upload, and prepare blocks.
      let filesToUpload = [];
      let blocks = context.state.blocks;
      blocks.forEach((block) => {
        let returnBlock;
        if (block.type === "image" && block.doUpload === true) {
          const uniqueFileName =
            authorId + "_" + crypto.randomBytes(20).toString("hex");

          if (block.imageFile.name === undefined) {
            console.log("!!!!!!!! File upload will fail");
          }

          filesToUpload.push({
            file: block.imageFile,
            metadata: {
              contentType: block.imageFileType,
              customMetadata: {
                postId: null,
                type: "template",
                fileName: uniqueFileName,
              },
            },
          });

          returnBlock = {
            doUpload: false,
            imageBlob: block.imageBlob,
            imageFile: null,
            imageFileType: block.imageFileType,
            imageUniqueName: uniqueFileName,
            imageUrl: null,
            type: "image",
          };
        } else {
          returnBlock = block;
        }

        validatedBlocks.push(returnBlock);
      });

      // Handle featured image upload.
      if (context.state.doFeaturedUpload) {
        const uniqueFileName =
          authorId + "_" + crypto.randomBytes(20).toString("hex");

        filesToUpload.push({
          file: context.state.featuredImage.file,
          metadata: {
            contentType: context.state.featuredImage.type,
            customMetadata: {
              postId: null,
              type: "template",
              fileName: uniqueFileName,
            },
          },
        });

        context.commit("setFeaturedImage", uniqueFileName);
      }

      let featuredImage = context.state.featuredImage;
      if (
        context.state.featuredImage === undefined ||
        context.state.featuredImage === null
      ) {
        featuredImage = null;
      }

      const templateData = {
        blocks: validatedBlocks,
        name: payload.name,
        imageUrls: context.state.imageUrls,
        featuredImage: featuredImage,
      };

      let templateId;

      const saveTemplatePromise = new Promise((resolve) => {
        if (payload.id === false) {
          templateData.authorId = authorId;
          templateData.authorName = authorName;
          templateData.createdAt = firebase.firestore.Timestamp.now();

          return db
            .collection("posts")
            .doc("settings")
            .collection("templates")
            .add(templateData)
            .then((doc) => {
              console.log("Created template: " + doc.id);
              templateId = doc.id;
              resolve();
            });
        } else {
          templateId = payload.id;

          return db
            .collection("posts")
            .doc("settings")
            .collection("templates")
            .doc(templateId)
            .update(templateData)
            .then(() => {
              console.log("Updated template: " + templateId);

              resolve();
            });
        }
      });

      return Promise.all([saveTemplatePromise]).then(() => {
        if (filesToUpload.length > 0) {
          console.log("Gonna upload images now");
          console.log(filesToUpload);
          console.log(templateId);

          return context
            .dispatch("uploadImages", {
              filesToUpload: filesToUpload,
              postId: templateId,
              type: "template",
            })
            .then((res) => {
              console.log(res);
              return;
            });
        }
      });

      // if (payload.id === false) {
      //   return db
      //     .collection("posts")
      //     .doc("settings")
      //     .collection("templates")
      //     .add({
      //       blocks: context.state.blocks,
      //       authorId: context.rootState.settings.user.id,
      //       authorName: context.rootState.settings.user.name,
      //       createdAt: firebase.firestore.Timestamp.now(),
      //       name: payload.name,
      //       imageUrls: {},
      //     });
      // } else {
      //   return db
      //     .collection("posts")
      //     .doc("settings")
      //     .collection("templates")
      //     .doc(payload.id)
      //     .update({
      //       blocks: context.state.blocks,
      //       authorId: context.rootState.settings.user.id,
      //       authorName: context.rootState.settings.user.name,
      //       createdAt: firebase.firestore.Timestamp.now(),
      //       name: payload.name,
      //       imageUrls: {},
      //     });
      // }
    }),
    loadTemplate: (context, payload) => {
      const template = context.getters.templateById(payload);
      if (template !== undefined) {
        context.commit("updateBlocks", template.blocks);
        context.commit("setImageUrls", template.imageUrls);
      }
      return;
    },
    deleteTemplate: firestoreAction((context, payload) => {
      return db
        .collection("posts")
        .doc("settings")
        .collection("templates")
        .doc(payload)
        .delete();
    }),
    renameTemplate: firestoreAction((context, payload) => {
      return db
        .collection("posts")
        .doc("settings")
        .collection("templates")
        .doc(payload.id)
        .update({ name: payload.name });
    }),
  },
};
export default notifications;
