import { db } from "../main.js";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/functions";

import { nanoid } from "nanoid";
// import { v4 as uuidv4 } from 'uuid';

/* eslint-disable */
const chat = {
  namespaced: true,
  state: {
    chatRooms: [],
    selectedChatAdmin: "all",
    selectedChatRoom: false,
    chatRoomMessages: [],
    notifications: {},
    lightboxImage: null,
    search: "",
    showCompany: true,
    showPersonal: true,
    massSelector: false,
    selectedCompanyCategories: [],
    selectedPriceGroups1: [],
    selectedPriceGroups2: [],
    selectedPriceGroups3: [],
    selectedRoutes: [],
    excludedPriceGroups1: [],
    excludedPriceGroups2: [],
    excludedPriceGroups3: [],
    excludedRoutes: [],
    selectedCompanies: [],
    selectedSalesAdmins: [],
    excludedCompanies: [],
    selectedType: "company",
    filterOpen: false,
    massSending: false,
  },
  mutations: {
    setSelectedChatAdmin: (state, payload) => {
      state.selectedChatAdmin = payload;
    },
    setSelectedChatRoom: (state, payload) => {
      state.selectedChatRoom = payload;
    },
    setLightboxImage: (state, payload) => {
      state.lightboxImage = payload;
    },
    setSearch: (state, payload) => {
      state.search = payload;
    },
    setShowCompany: (state, payload) => {
      state.showCompany = payload;
    },
    setShowPersonal: (state, payload) => {
      state.showPersonal = payload;
    },
    setMassSelector: (state, payload) => {
      state.massSelector = payload;
    },
    setSelectedCompanyCategories: (state, payload) => {
      state.selectedCompanyCategories = payload;
    },
    setSelectedPriceGroups1: (state, payload) => {
      state.selectedPriceGroups1 = payload;
    },
    setSelectedPriceGroups2: (state, payload) => {
      state.selectedPriceGroups2 = payload;
    },
    setSelectedPriceGroups3: (state, payload) => {
      state.selectedPriceGroups3 = payload;
    },
    setSelectedRoutes: (state, payload) => {
      state.selectedRoutes = payload;
    },
    setExcludedPriceGroups1: (state, payload) => {
      state.excludedPriceGroups1 = payload;
    },
    setExcludedPriceGroups2: (state, payload) => {
      state.excludedPriceGroups2 = payload;
    },
    setExcludedPriceGroups3: (state, payload) => {
      state.excludedPriceGroups3 = payload;
    },
    setExcludedRoutes: (state, payload) => {
      state.excludedRoutes = payload;
    },
    setSelectedCompanies: (state, payload) => {
      state.selectedCompanies = payload;
    },
    setSelectedSalesAdmins: (state, payload) => {
      state.selectedSalesAdmins = payload;
    },
    setExcludedCompanies: (state, payload) => {
      state.excludedCompanies = payload;
    },
    setSelectedType: (state, payload) => {
      state.selectedType = payload;
    },
    setFilterOpen: (state, payload) => {
      state.filterOpen = payload;
    },
    setMassSending: (state, payload) => {
      state.massSending = payload;
    },
  },

  getters: {
    indexOfFirstIncomingMessage: (state) => {
      let index = state.chatRoomMessages.findIndex(
        (message) => message.admin === false
      );
      return index;
    },
    indexOfFirstAdminMessage: (state) => {
      let index = state.chatRoomMessages.findIndex(
        (message) => message.admin === true
      );
      return index;
    },
    chatMessageByIndex: (state) => (index) => {
      return state.chatRoomMessages[index];
    },
    selectedChatRoomData: (state, getters) => {
      return getters.combinedChatRooms.find(
        (room) => room.id === state.selectedChatRoom
      );
    },
    getAccountOwner: (state, getters) => (roomId) => {
      let room = getters.combinedChatRooms.find((room) => room.id === roomId);
      if (room === undefined) {
        return 0;
      }
      return room.accountOwner;
    },
    combinedChatRooms: (state) => {
      let rooms = [];
      for (const [key, value] of Object.entries(state.chatRooms.company)) {
        rooms.push(value);
      }
      for (const [key, value] of Object.entries(state.chatRooms.personal)) {
        rooms.push(value);
      }

      return rooms;
    },
    getShortlistName:
      (state, getters, rootState, rootGetters) => (shortlistId) => {
        let companyId;

        if (getters.selectedChatRoomData.type == "company") {
          companyId = getters.selectedChatRoomData.identifier;
        } else {
          companyId = rootGetters["users/getUserCompanyById"](
            getters.selectedChatRoomData.identifier
          );
        }

        const company = rootGetters["companies/getCompanyByVismaId"](companyId);

        if (company.shortlists[shortlistId] !== undefined) {
          return company.shortlists[shortlistId].name;
        } else {
          return "Unavailable shortlist";
        }
      },
    sortedFilteredChatRooms: (state, getters) => {
      let rooms = [];

      if (state.showPersonal) {
        for (const [key, value] of Object.entries(state.chatRooms.personal)) {
          if (value.name == undefined) {
            console.log(key);
            console.log(value);
          }
          rooms.push(value);
        }
      }

      if (state.showCompany) {
        for (const [key, value] of Object.entries(state.chatRooms.company)) {
          if (value.name == undefined) {
            console.log(key);
          }
          rooms.push(value);
        }
      }

      if (state.selectedChatAdmin !== "all") {
        rooms = rooms.filter(
          (room) => room.accountOwner === state.selectedChatAdmin
        );
      }

      if (state.search !== "") {
        rooms = rooms.filter((room) =>
          room.name.toLowerCase().includes(state.search.toLowerCase())
        );
      }

      rooms.map((room) => {
        if (room == undefined || room.name == undefined) {
          console.log(room);
        }
      });


      rooms.sort((a, b) => a.name.localeCompare(b.name, "sv"));

      rooms.sort((a, b) => {
        if (a.timestamp === null) {
          return 1;
        } else if (b.timestamp === null) {
          return -1;
        } else {
          return b.timestamp - a.timestamp;
        }
      });

      // Put rooms with notifications on top
      rooms.sort((a, b) => {
        if (
          state.notifications[a.id] !== undefined &&
          state.notifications[b.id] === undefined
        ) {
          return -1;
        } else if (
          state.notifications[a.id] === undefined &&
          state.notifications[b.id] !== undefined
        ) {
          return 1;
        } else {
          return 0;
        }
      });

      return rooms;
    },
    selectedAdminCompanyChatRooms:
      (state, getters, rootState) => (searchString) => {
        let chatRooms;
        let returnArr = [];

        // Base chatrooms
        if (state.selectedChatAdmin === "all") {
          chatRooms = getters.companyChatRooms;
        } else {
          chatRooms = getters.companyChatRooms.filter(
            (room) => room.accountOwnerVismaId === state.selectedChatAdmin
          );
        }

        // Search filtering
        if (searchString) {
          chatRooms = chatRooms.filter((room) =>
            room.name.toLowerCase().includes(searchString.toLowerCase())
          );
        }

        // Notifications and userOwner
        chatRooms.forEach((room) => {
          let notifications = 0;
          let currentUserOwner = false;

          if (state.notifications !== null) {
            if (state.notifications[room.id] !== undefined) {
              notifications = state.notifications[room.id].count;
            }
          }

          if (room.accountOwnerVismaId === rootState.settings.user.vismaId) {
            currentUserOwner = true;
          }

          room.notifications = notifications;
          room.currentUserOwner = currentUserOwner;
          returnArr.push(room);
        });

        // Sort first by company name, then by notification count
        return returnArr
          .sort((a, b) => a.name.localeCompare(b.name, "sv"))
          .sort((a, b) => b.notifications - a.notifications);
      },
    selectedAdminPersonalChatRooms:
      (state, getters, rootState) => (searchString) => {
        let chatRooms;
        let returnArr = [];

        if (state.selectedChatAdmin === "all") {
          chatRooms = getters.personalChatRooms;
        } else {
          chatRooms = getters.personalChatRooms.filter(
            (room) => room.accountOwnerVismaId === state.selectedChatAdmin
          );
        }

        // Search filtering
        if (searchString) {
          chatRooms = chatRooms.filter((room) =>
            room.name.toLowerCase().includes(searchString.toLowerCase())
          );
        }

        chatRooms.forEach((room) => {
          let notifications = 0;
          let currentUserOwner = false;

          if (state.notifications !== null) {
            if (state.notifications[room.id] !== undefined) {
              notifications = state.notifications[room.id].count;
            }
          }

          if (room.accountOwnerVismaId === rootState.settings.user.vismaId) {
            currentUserOwner = true;
          }

          room.notifications = notifications;
          room.currentUserOwner = currentUserOwner;

          if (room.name == undefined) {
            console.log("no name:");
            console.log(room.id);
          }
          returnArr.push(room);
        });

        return returnArr
          .sort((a, b) => a.name.localeCompare(b.name))
          .sort((a, b) => b.notifications - a.notifications);
      },
    notificationCountByAdmin: (state, getters) => (admin) => {
      // let personalChatRooms = getters.personalChatRooms.filter(
      //   (room) => room.accountOwnerVismaId === admin
      // );
      // let companyChatRooms = getters.companyChatRooms.filter(
      //   (room) => room.accountOwnerVismaId === admin
      // );

      let total = 0;

      Object.keys(state.notifications).forEach((key) => {
        const chatRoom = getters.combinedChatRooms.find(
          (room) => room.id === key
        );
        if (chatRoom !== undefined) {
          if (chatRoom.accountOwner === admin) {
            total += state.notifications[key].count;
          }
        }
      });
      return total;
    },
    totalNotificationCount: (state) => {
      let total = 0;

      if (!state.notifications) {
        return total;
      }

      Object.keys(state.notifications).forEach((key) => {
        total += state.notifications[key].count;
      });
      return total;
    },
    adminUsersWithChatRooms: (state, getters, rootState, rootGetters) => {
      let admins = rootGetters["users/getAdminUsers"];
      let returnArr = [];

      admins.forEach((admin) => {
        let companyChatRooms = getters.combinedChatRooms.filter(
          (room) => room.accountOwner === admin.vismaId
        );

        if (companyChatRooms.length > 0) {
          returnArr.push(admin);
        }
      });

      return returnArr;
    },
    selectedChatRoomName: (state, getters) => {
      if (!state.selectedChatRoom) {
        return "No room";
      }

      if (state.selectedChatRoom === "mass") {
        return "Mass selection";
      }

      let chatRoom = getters.combinedChatRooms.find(
        (room) => room.id === state.selectedChatRoom
      );
      if (chatRoom === undefined) {
        return "ERROR";
      }

      return chatRoom.name;
    },
    getChatRoomById: (state, getters) => (chatRoomId) => {
      const chatRoom = getters.combinedChatRooms.find((room) => {
        return room.id === chatRoomId;
      });

      if (chatRoom === undefined) {
        return false;
      }
      return chatRoom;
    },
    finalCompanies: (state, getters, rootState) => {
      let companies = rootState.companies.companies;
      return companies
        .filter((company) => {
          if (
            state.selectedCompanyCategories.length > 0 &&
            state.selectedCompanyCategories.indexOf(
              company.companyCategory.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedPriceGroups1.length > 0 &&
            state.selectedPriceGroups1.indexOf(
              company.companyPriceGroup.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedPriceGroups2.length > 0 &&
            state.selectedPriceGroups2.indexOf(
              company.companyPriceGroup2.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedPriceGroups3.length > 0 &&
            state.selectedPriceGroups3.indexOf(
              company.companyPriceGroup3.toString()
            ) !== -1
          ) {
            return true;
          }

          if (company.route === undefined) {
            console.log(company);
          }

          if (
            state.selectedRoutes.length > 0 &&
            state.selectedRoutes.indexOf(company.route.toString()) !== -1
          ) {
            return true;
          }

          if (
            state.selectedCompanies.length > 0 &&
            state.selectedCompanies.indexOf(company.vismaId) !== -1
          ) {
            return true;
          }

          return false;
        })
        .filter((company) => {
          if (
            state.excludedCompanies.length > 0 &&
            state.excludedCompanies.indexOf(company.vismaId) !== -1
          ) {
            return false;
          }

          if (
            state.excludedPriceGroups1.length > 0 &&
            state.excludedPriceGroups1.indexOf(
              company.companyPriceGroup.toString()
            ) !== -1
          ) {
            return false;
          }

          if (
            state.excludedPriceGroups2.length > 0 &&
            state.excludedPriceGroups2.indexOf(
              company.companyPriceGroup2.toString()
            ) !== -1
          ) {
            return false;
          }

          if (
            state.excludedPriceGroups3.length > 0 &&
            state.excludedPriceGroups3.indexOf(
              company.companyPriceGroup3.toString()
            ) !== -1
          ) {
            return false;
          }

          if (
            state.excludedRoutes.length > 0 &&
            state.excludedRoutes.indexOf(company.route.toString()) !== -1
          ) {
            return false;
          }

          return true;
        })
        .filter((company) => {
          if (state.selectedSalesAdmins.length > 0) {
            if (
              state.selectedSalesAdmins.find(
                (salesAdmin) => salesAdmin === company.accountOwnerVismaId
              ) !== undefined
            ) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        })
        .map((company) => {
          return {
            vismaId: company.vismaId,
            firebaseId: company.id,
          };
        });
    },
  },
  actions: {
    bindAdminChatNotifications: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef(
        "notifications",
        db.collection("app").doc("adminChatNotifications")
      );
    }),
    bindChatRooms: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "chatRooms",
        db.collection("app").doc("chatRooms")
      );
    }),
    unbindChatRooms: firestoreAction((context) => {
      return context.unbindFirestoreRef("chatRooms");
    }),
    unbindChatRoom: firestoreAction((context) => {
      return context.unbindFirestoreRef("chatRoomMessages");
    }),
    bindChatRoom: firestoreAction((context, payload) => {
      context.commit("setSelectedChatRoom", payload);

      if (payload === "mass") {
        return context.bindFirestoreRef(
          "chatRoomMessages",
          db
            .collection("massChatMessages")
            .orderBy("timestamp", "desc")
            .limit(50)
        );
      } else {
        return context.bindFirestoreRef(
          "chatRoomMessages",
          db
            .collection("chatRooms")
            .doc(payload)
            .collection("messages")
            .orderBy("timestamp", "desc")
            .limit(50)
        );
      }
    }),
    sendMessage: async (context, payload) => {
      const messageData = {
        message: payload.message,
        timestamp: firebase.firestore.Timestamp.now(),
        author: payload.author,
        authorName: payload.authorName,
        readableTime: payload.readableTime,
        admin: payload.admin,
        image: payload.imageFile ? true : false,
        imageFull: null,
        imageResized: null,
        productId: payload.productId !== false ? payload.productId : null,
        shortlistId: payload.shortlistId,
      };

      // Get all chat rooms based on final companies.
      if (payload.chatId === "mass") {
        const finalCompanies = context.getters.finalCompanies;
        const users = context.rootState.users.userList;

        let chatRoomIds = [];
        let userIds = [];
        let userIdsChatRoomIds = {};

        userIds = users
          .filter((user) => {
            const found = finalCompanies.find(
              (company) => company.vismaId === user.companyId
            );
            if (found !== undefined) {
              return true;
            }
            return false;
          })
          .map((user) => {
            if (context.state.selectedType === "company") {
              const chatRoom = Object.values(
                context.state.chatRooms.company
              ).find((room) => room.identifier === user.companyId);
              if (chatRoom !== undefined) {
                chatRoomIds.push(chatRoom.id);
                userIdsChatRoomIds[user.uid] = chatRoom.id;
              }
            } else if (context.state.selectedType === "personal") {
              const chatRoom = Object.values(
                context.state.chatRooms.personal
              ).find((room) => room.identifier === user.uid);
              if (chatRoom !== undefined) {
                chatRoomIds.push(chatRoom.id);
                userIdsChatRoomIds[user.uid] = chatRoom.id;
              }
            }

            return user.uid;
          });

        // Also find chat rooms that have no users
        if (context.state.selectedType === "company") {
          finalCompanies.forEach((company) => {
            const chatRoom = Object.values(
              context.state.chatRooms.company
            ).find((room) => room.identifier === company.vismaId);
            if (chatRoom !== undefined) {
              chatRoomIds.push(chatRoom.id);
            }
          });
        }

        chatRoomIds = [...new Set(chatRoomIds)];

        console.log(chatRoomIds);

        // context.dispatch("unbindChatRooms");

        var adminMassSendChatMessage = firebase
          .functions()
          .httpsCallable("adminMassSendChatMessage");

        const result = await adminMassSendChatMessage({
          messageData: messageData,
          chatRoomIds: chatRoomIds,
          userIdsChatRoomIds: userIdsChatRoomIds,
          roomType: context.state.selectedType,
        });

        // context.dispatch("bindChatRooms");
        console.log(result);
        return;
      } else {
        return db
          .collection("chatRooms")
          .doc(payload.chatId)
          .collection("messages")
          .add(messageData)
          .then((doc) => {
            console.log(doc.id);

            if (payload.imageFile) {
              const uniqueFileName = nanoid();
              const remoteFullPath =
                "chatRooms/" + payload.chatId + "/" + uniqueFileName;
              const storageRef = firebase.storage().ref().child(remoteFullPath);

              console.log(encodeURIComponent(uniqueFileName));

              console.log(remoteFullPath);

              const metadata = {
                contentType: payload.imageFileType,
                public: true,
                customMetadata: {
                  type: "chat",
                  messageId: doc.id,
                  chatRoomId: payload.chatId,
                  authorId: payload.author,
                  // firebaseStorageDownloadTokens: uuid,
                },
                // metadata: {
                //     firebaseStorageDownloadTokens: uuid,
                // }
              };

              return storageRef
                .put(payload.imageFile, metadata)
                .then(() => {
                  console.log("File upload completed");
                })
                .catch((error) => {
                  console.log(error);
                  console.log(error.message);
                });
            }
          });
      }
    },
    removeNotification: firestoreAction((context, payload) => {
      return db
        .collection("app")
        .doc("adminChatNotifications")
        .update({
          [payload]: firebase.firestore.FieldValue.delete(),
        });
    }),
    addNotification: firestoreAction((context, payload) => {
      return db
        .collection("app")
        .doc("adminChatNotifications")
        .update({
          [payload + ".count"]: firebase.firestore.FieldValue.increment(1),
          [payload + ".createdAt"]: firebase.firestore.FieldValue.arrayUnion(
            firebase.firestore.Timestamp.now()
          ),
        });
    }),
    markMessageAsRead: firestoreAction((context, payload) => {
      const chatRoomId = payload.chatRoomId;
      const messageId = payload.messageId;
      const name = payload.name;

      console.log(chatRoomId);
      console.log(messageId);
      console.log(name);

      return db
        .collection("chatRooms")
        .doc(chatRoomId)
        .collection("messages")
        .doc(messageId)
        .update({
          readBy: firebase.firestore.FieldValue.arrayUnion(name),
        })
        .then(() => {
          console.log("Message marked as read");
          return;
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    }),
    updateSelection: function (context, payload) {
      const type = payload.type;
      const field = payload.field;
      const value = payload.value;

      let newSelection = context.state["selected" + field];

      // Do array action.
      if (type === "add") {
        newSelection = newSelection
          .filter((val) => val !== value)
          .concat(value);
      } else if (type === "remove") {
        newSelection = newSelection.filter((val) => val !== value);
      }

      context.commit("setSelected" + field, newSelection);

      console.log(context.state["selected" + field]);
    },
    updateExclusion: function (context, payload) {
      const type = payload.type;
      const field = payload.field;
      const value = payload.value;

      let newSelection = context.state["excluded" + field];

      // Do array action.
      if (type === "add") {
        newSelection = newSelection
          .filter((val) => val !== value)
          .concat(value);
      } else if (type === "remove") {
        newSelection = newSelection.filter((val) => val !== value);
      }

      context.commit("setExcluded" + field, newSelection);
    },
    clearAllSelections: function (context) {
      context.commit("setSelectedCompanyCategories", []);
      context.commit("setSelectedPriceGroups1", []);
      context.commit("setSelectedPriceGroups2", []);
      context.commit("setSelectedPriceGroups3", []);
      context.commit("setExcludedPriceGroups1", []);
      context.commit("setExcludedPriceGroups2", []);
      context.commit("setExcludedPriceGroups3", []);
      context.commit("setSelectedCompanies", []);
      context.commit("setSelectedSalesAdmins", []);
      context.commit("setExcludedCompanies", []);
      context.commit("setSelectedRoutes", []);
      context.commit("setExcludedRoutes", []);
      context.commit("setSelectedType", "company");
    },
    selectAll: function (context, payload) {
      const selection = Object.keys(payload).map((key) => {
        return key;
      });
      context.commit("setSelectedCompanyCategories", selection);
    },
  },
};
export default chat;
