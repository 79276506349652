import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/companies",
    name: "Companies",
    component: () => import("../views/Companies.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/companies/:id",
    name: "SingleCompany",
    component: () => import("../views/SingleCompany.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Users.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/:id",
    name: "SingleUser",
    component: () => import("../views/SingleUser.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/Products.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/products-gs1",
    name: "ProductsGS1",
    component: () => import("../views/ProductsGS1.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/price-lists",
    name: "PriceLists",
    component: () => import("../views/PriceLists.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/shared-shortlists",
    name: "SharedShortlists",
    component: () => import("../views/SharedShortlists.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/company-shortlists",
    name: "CompanyShortlists",
    component: () => import("../views/CompanyShortlists.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mass-edit-shortlists",
    name: "MassEditShortlists",
    component: () => import("../views/MassEditShortlists.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mass-add-shortlists",
    name: "MassAddShortlists",
    component: () => import("../views/MassAddShortlists.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../views/Orders.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders/:id",
    name: "SingleOrder",
    component: () => import("../views/SingleOrder.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("../views/Notifications.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/popups",
    name: "Popups",
    component: () => import("../views/Popups.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/surveys",
    name: "Surveys",
    component: () => import("../views/Surveys.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chat",
    name: "Chat",
    component: () => import("../views/Chat.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dev",
    name: "Dev",
    component: () => import("../views/Dev.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/app-settings",
    name: "App settings",
    component: () => import("../views/AppSettings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/languages",
    name: "Languages",
    component: () => import("../views/Languages.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: () => import("../views/Analytics.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register-analytics",
    name: "Register analytics",
    component: () => import("../views/RegisterAnalytics.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/system",
    name: "System",
    component: () => import("../views/System.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/cart-upsell",
    name: "Cart upsell",
    component: () => import("../views/CartUpsell.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("../views/Categories.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "*",
    component: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
